.MicroGreens {
    text-align: center;
    padding: 1px;
  }

  .h1-MicroGreens {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;

    color: #264653;
    
  }

  .h2-MicroGreens {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: #333333;
    
  }

  .h4-MicroGreens {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: #333333;
    
  }
  .p-MicroGreens {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    text-align: justify;    
    padding-left: 10%;
    padding-right: 10%;    
    color: #333333;
    line-height: 1.6;
    margin-bottom: 20px;
    
  }
  .p-feedback-MicroGreens {
    font-family: "Arvo", serif;
    font-weight: 400;
    
    font-style: italic;
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
    color: #6C757D;
    line-height: 1.6;
  }

  .divider-MicroGreens {
    border: none;
    height: 2px;
    background-color: black;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 80%;
    color: #333333;
  }
  

  .microgreens-image-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px; 
    color: #264653;
  }
  
  .microgreens-image {
    width:80%;  
    height: 20%;  
    justify-content: right;
  }

  .image-cycler {
    width: 80%;
    margin-left: 20%;
  }
