.Projects {
  text-align: center;
  padding: 1px;
}

.header1-Projects {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;  
  text-decoration: underline;
  padding: 5px;
  color: #264653;
}

.header2-Projects {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  padding: 5px;
  color: #264653;
}

.descricao-Projects {
  font-family: "Arvo", serif;
  font-weight: 400;
  font-style: normal;
  text-align: justify;
  padding: 5px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  color: #333333;
  line-height: 1.6;
}

.contact-button {
  background-color: #264653; 
  border: none;
  color: white;
  font-weight: 400;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Arvo", serif;
  transition: background-color 0.5s ease;
}



.button-link {
  color: inherit;  
  text-decoration: none;
}

.button-link:hover, .button-link:focus, .button-link:active {
  color: inherit;  
  text-decoration: none;
}


.divider {
  border: none;
  height: 2px;
  background-color: #333333;
  margin: 20px auto;
  width: 80%;
}

.contact-item-projetos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px; 
  }
  
  .icon-projetos {
    width: 20%;  
    height: 20%;  
    margin-right: 0;
    justify-content: center;
  }

  .icon-text-projetos  {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .descricao-Cliente {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    color: #333333;
  }

  .arvo-regular-projetos  {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    padding-left: 10%;
    font-size: 80%;
    color: #333333;
  }