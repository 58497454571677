body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  background-color: #FFFFFF; /* Cor de fundo cinza médio */
  min-height: 100vh; /* Altura mínima de 100% da viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.App-content {
  width: 100%;
  padding-top: 60px; /* Espaço para a barra de navegação */
  flex-grow: 1; /* Para garantir que o conteúdo preencha o espaço disponível */
}
