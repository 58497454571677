.PriceTracker {
    text-align: center;
    padding: 1px;
  }

  .h1-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;

    color: #264653;
    
  }

  .h2-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: #333333;
    
  }
  .h3-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: #333333;
    text-align: left; 
    
  }

  .h4-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: #333333;
    
  }
  .p-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    text-align: justify;    
    padding-left: 10%;
    padding-right: 10%;    
    color: #333333;
    line-height: 1.6;
    margin-bottom: 20px;
    
  }
  .p-feedback-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 400;
    
    font-style: italic;
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
    color: #6C757D;
    line-height: 1.6;
  }

  .divider-PriceTracker {
    border: none;
    height: 2px;
    background-color: black;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 80%;
    color: #333333;
  }
  

  .PriceTracker-image-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px; 
    color: #264653;
  }
  
  .PriceTracker-image {
    width:80%;  
    height: 20%;  
    margin-right: 0;
    justify-content: right;
  }
  .ul-PriceTracker {
    list-style-type: disc;
    padding-left: 15%;
    padding-right: 10%;
  }
  
  .li-PriceTracker {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    text-align: justify;
    margin-bottom: 5px;
  }
