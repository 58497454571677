.AboutMe {
    padding: 1%;
  }


  .arvo-regular {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
    color: #333333;
    line-height: 1.6;
  }
  
  .arvo-cab {
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    padding: 5px;
    color: #264653;    
  }


  .arvo-bold-contacto {
    
    font-family: "Arvo", serif;
    font-weight: 700;
    font-style: normal;
    color: #264653;
    
  }

  .arvo-regular-contacto {
    font-family: "Arvo", serif;
    font-weight: 400;
    font-style: normal;
    padding-left: 10%;
    padding-right: 10%;
    text-decoration: underline;
    
    color: #333333;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px; 
    color: #264653;
  }
  
  .icon {
    width: 30px;  
    height: 30px;  
    margin-right: 0;
    justify-content: right;
  }

  .icon-text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #333333;
    
  }

  .black-link {
    color: #333333;
    text-decoration: none;
  }
  
  .black-link:hover, .black-link:focus, .black-link:active {
    color: #333333;
    text-decoration: underline;
  }


  .divider-MicroGreens {
    border: none;
    height: 2px;
    background-color: black;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 80%;
    color: #333333;
  }